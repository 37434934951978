import React from 'react';
import Title from '../Title';
import './style.scss';

const data = ['team1', 'team2', 'team3'];

const OurTeam = ({ tr }) => (
  <div className='our__container'>
    <div className='content'>
      <h2>{tr.our_team}</h2>
      <div>
        {data.map((el, index) => (
          <div className='container_our' key={el}>
            <img src={`/Images/${el}.jpg`} alt='Avatar' className='itemImg' />
            <div className='overlay_our'>
              <p className='text_our_name'>
                {' '}
                {index === 0 && 'Vardan'} {index === 1 && 'Hovhannes'}{' '}
                {index === 2 && 'Ani'}
              </p>

              <p className='text_our'>
                {' '}
                {index === 0 && tr.hover0} {index === 1 && tr.hover1}{' '}
                {index === 2 && tr.hover2}
              </p>
            </div>
          </div>
        ))}
        <div className='members'>
          <span>
            50+ <br /> {tr.members}
          </span>
          <img src={`/Images/8hogi-min.jpeg`} alt='' className='teamImg' />
        </div>
      </div>
      <Title className='title_lg' color='#fff' highlighted size={64}>
        {tr.we_speak_German}
      </Title>
    </div>
  </div>
);

export default OurTeam;
