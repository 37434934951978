const technologies = [
  {
    title: 'Data analytics',
    links: [
      {
        title: 'Databricks',
        id: 'databrick'
      },
      {
        title: 'Stream Analytics',
        id: 'stream'
      },
      {
        title: 'Data Lake Analytics',
        id: 'datalake'
      },
      {
        title: 'Data Factory',
        id: 'factory'
      }
    ]
  },
  {
    title: 'Compute',
    links: [
      {
        title: 'Virtual Machines',
        id: 'machine'
      },
      {
        title: 'Azure Functions',
        id: 'azure'
      },
      {
        title: 'Container Instances',
        id: 'instances'
      },
      {
        title: 'Service Fabric',
        id: 'fabric'
      },
      {
        title: 'Container Registry',
        id: 'registry'
      },
      {
        title: 'MS Power BI',
        id: 'power'
      },
      {
        title: 'Tableau',
        id: 'softvare'
      }
    ]
  },
  {
    title: 'Identy',
    links: [
      {
        title: 'Azure Active Directory',
        id: 'd1'
      },
      {
        title: 'Azure Active Directory',
        id: 'd3'
      }
    ]
  },
  {
    title: 'Storage',
    links: [
      {
        title: 'Database for PostgreSQL',
        id: 'post'
      },
      {
        title: 'Cosmos DB',
        id: 'cosmos'
      },
      {
        title: 'SQL Database',
        id: 'sql'
      },
      {
        title: 'MongoDB',
        id: 'redis'
      },
      {
        title: 'Database for MariaDB',
        id: 'maria'
      },
      {
        title: 'Azure Firewall',
        id: 'firewall'
      }
    ]
  },
  {
    title: 'Platform As Service ',
    links: [
      {
        title: 'AWS Elastic Beanstalk',
        id: 'awselastic'
      },
      {
        title: 'AWS Elastic Container Service',
        id: 'awscont'
      },
      {
        title: 'Amazon ECS for Kubernets',
        id: 'amazon'
      },
      {
        title: 'AWS Fargate',
        id: 'awsfar'
      }
    ]
  },
  {
    title: 'Integration ',
    links: [
      {
        title: 'Events',
        id: 'eventS'
      },
      {
        title: 'API Management',
        id: 'api'
      },
      {
        title: 'Logic Apps',
        id: 'logic'
      },
      {
        title: 'Service Bus',
        id: 'bus'
      }
    ]
  },
  {
    title: 'Networking',
    links: [
      {
        title: 'Application Gateway',
        id: 'l1'
      },
      {
        title: 'Virtual Network',
        id: 'l2'
      },
      {
        title: 'Traffic Manager',
        id: 'l3'
      }
    ]
  },
  {
    title: 'Devops ',
    links: [
      {
        title: 'Terraform ',
        id: 'l4'
      },
      {
        title: '',
        id: 'jenkins'
      },
      {
        title: '',
        id: 'bamboo'
      },
      {
        title: '',
        id: 'gitlab'
      }
    ]
  }
];

export default technologies;
