const translation = {
  de: {
    // Above Top Menu
    aboutUs: 'Unser Standort in Deutschland',

    // Top Menu Items
    offerings: 'Services',
    iotSolutions: 'IoT Solutions',
    technology: 'Cloud native solutions',
    AiMl: 'AI/ML and Data',
    portfolio: 'Referenzen',
    contactUs: 'Kontakt',
    imprint: 'Imprint',
    careers: 'Karriere',

    // tooltip:`switch language to English`,
    tooltip: `auf Englisch wechseln`,

    employers:
      'AKNA beschäftigt Software-Ingenieure mit hervorragender beruflicher Laufbahn und sehr gefragten Fähigkeiten.',

    //SECTION 1: IoT Solutions
    solutions: 'LÖSUNGEN',
    industry: 'Wir entwickeln IoT- und Industrie 4.0-Lösungen.',
    point: 'Wir verbinden Millionen von Geräten und Milliarden von Daten.',
    text1:
      'Für einen der weltweit führenden Anbieter von Premium- und Luxusfahrzeugen haben wir eine IoT-Plattform entwickelt.',
    text2:
      'Unser Team hat eine skalierbare und mandantenfähige Cloud-basierte IoT-Plattform für Millionen von Bluetooth-Geräten entwickelt, die auf der ganzen Welt eingesetzt wird.',
    text3:
      'Bahnbetriebe in ganz Europa nutzen IoT-Sensoren und Software, die von unseren Ingenieuren entwickelt wurden.',
    text4:
      'Für zwei Berliner Start-ups entwickeln wir Lösungen zur Vorhersage von Anlagenausfällen und zur Wartungsverbesserung auf Grundlage von IoT-Echtzeitdaten.',

    //TODO Add country translations in this form
    section1_country1: 'Deutschland',
    section1_country2: 'Schweiz',
    section1_country3: 'Deutschland',
    section1_country4: 'Deutschland',

    //SECTION 2: Cloud native solutions
    cloud: 'Cloud',
    native: ' Native Solutions',
    section2_subtitle: 'Wir unterstützen Azure, AWS und weitere Technologien',

    //SECTION 3: AI and Data projects
    mlaisolutions: 'Unsere jüngsten AI/ML- und Datenprojekte',
    mlindustry:
      'Wir bauen optimierte und robuste Datenplattformen auf, die Qualität gewährleisten und datengesteuerte Produkte ermöglichen.',
    mltext1:
      'Unsere Ingenieure entwickeln eine Azure-basierte Business Intelligence-Plattform für eines der weltweit führenden Medizintechnikunternehmen. Sie verwenden dabei Azure DataBricks, Synapse, PowerApps, Data Lake Gen2, PowerBI und mehr.',
    mltext2:
      'Seit zwei Jahren entwickeln wir gemeinsam eine Enterprise Knowledge Graph Softwarelösung für einen innovativen deutschen Anbieter. Die Software bietet eine kundenspezifische und responsive Benutzeroberfläche für Modellierung und Mapping.',
    mltext3:
      'Wir unterstützen die Entwicklung einer Kernplattform, die VR- und AR-Dienste und 3D-Modellierungswerkzeuge für ein herausragendes VR-Unternehmen bereitstellt.',
    mltext4:
      'Unsere Ingenieure entwickelten eine Big-Data- und fortschrittliche Analytics Lösung für Preisoptimierung und gezieltes Marketing für ein Telekommunikationsunternehmen.',

    //TODO Add country translations in this form
    section3_country1: 'Schweiz',
    section3_country2: 'Deutschland',
    section3_country3: 'Deutschland',
    section3_country4: 'Bahamas',

    country: 'Land',

    germany: 'Deutschland',
    armenia: 'Armenien',
    switzerland: 'Schweiz',
    bahamas: 'Bahamas',
    //TODO check the translation usage
    text: 'IoT-Sensoren, Hardware- und Softwareentwicklung für städtische Mobilitätslösungen. Eingesetzt von öffentlichen Verkehrsbetrieben in ganz Europa',

    // clients
    clients_title: 'EINIGE UNSERER KUNDEN',
    clients_text:
      'Unsere Erfahrung und Zuverlässigkeit beruht auf der Bereitstellung zahlreicher Lösungen, Teams und Produkte.',

    //our team
    our_team: 'Unser Team',
    members: 'Mitarbeiter',
    we_speak_German: 'WIR SPRECHEN DEUTSCH',
    hover0: 'Co-Founder und Cloud Engineer',
    hover1: '.NET Core und Azure Entwickler',
    hover2: 'Python und AWS Entwickler',

    //available profiles
    skills: 'Kenntnisse',
    projects: 'Projekte',
    experience: 'Erfahrung',
    available_consultants: 'Verfügbare Berater',
    available_experts: 'Aktuell verfügbare Experten für Ihr Projekt.',

    //our location
    our_location_title: 'UNSERE STANDORTE',
    we_support: 'WE SUPPORT EVERYWHERE',

    //carrers
    be_a_part_of_us: 'SEI EIN TEIL VON AKNA TECH',
    support_everyone: 'WE SUPPORT EVERYONE',
    careers_description:
      ' Bei Akna Tech übernimmt jeder Verantwortung und trägt so zu unserem Erfolg bei. Unsere Arbeit ist agil und flexibel. Wer bei Akna Tech mitmacht, kann an innovativen Projekten und Produkten weltweit führender Unternehmen und wachsenden Startups arbeiten.',

    //AccordionBox
    your_benefits: 'Deine Vorteile:',
    your_qualification: 'Deine qualifikation:',
    your_contribution: 'Deine aufgaben:',
    //job1
    job1_1:
      'Betreuung und Weiterentwicklung der cloudbasierten Systemlandschaft',
    job1_2:
      'Sicherstellung der Effizienz und Effektivität von Anwendungen in der Azure-Umgebung durch Automatisierung und Standardisierung',
    job1_3: 'Automatisierung der Entwicklungs- und Bereitstellungsprozesse',
    job1_4:
      'Entwerfen, Bereitstellen und Verbessern von Lösungen für den Betrieb von Containern',
    job1_5: 'Stellen Sie Testautomatisierungstools bereit',
    job1_6:
      'Einschlägige Berufserfahrung und gute Anwendungskenntnisse der Azure Cloud',
    job1_7: 'Sicherer Umgang mit Containern und Kubernetes',
    job1_8: 'Kenntnisse in .NET ',
    job1_9: 'Kenntnisse über Entwicklungsprozesse (CI/CD)',
    job1_10:
      'Gute Kenntnisse in Linux, Virtualisierung und Datenbanken (z. B. MS SQL, MySQL oder Maria DB)',
    job1_11:
      'Bestenfalls verfügen Sie über Zertifizierungen für Azure (z. B. Azure Developer, DevOps Engineer, Azure Solutions Architect) oder Kubernetes',
    job1_12: 'Gute Kommunikationsfähigkeiten ',
    job1_13: 'Sehr gute Englischkenntnisse ',
    job1_14:
      'Anwendung modernster Technologien in einer leistungsstarken Entwicklungsumgebung ',
    job1_15: 'Eine wertschätzende und kollaborative Unternehmenskultur ',
    job1_16: 'Flexibilität bei der Arbeitsgestaltung ',
    job1_17: 'Regelmäßige Fortbildungs- und Entwicklungsmöglichkeiten ',
    job1_18:
      'Flache Hierarchien mit offenen Türen in einer entspannten, aber professionellen Atmosphäre ',
    //job2
    job2_1: 'Planen Sie Projekte',
    job2_2: 'Konzipieren Sie visuelle Elemente basierend auf den Anforderungen',
    job2_3: 'Erstellen Sie grobe Entwürfe und präsentieren Sie Ideen',
    job2_4:
      'Entwickeln Sie Illustrationen, Storyboards und andere Designs mithilfe von Software oder von Hand',
    job2_5: 'Verwenden Sie für jede Grafik die passenden Farben und Layouts',
    job2_6: 'Testen Sie Grafiken in verschiedenen Medien',
    job2_7: 'Leidenschaft für Grafikdesign',
    job2_8:
      'Vertraut mit Designsoftware und -technologien (wie InDesign, Illustrator, Photoshop)',
    //job_3
    job3_1: 'Du bist zuständig für den Aufbau und die kontinuierliche Pflege der Kundenkontakte.',
    job3_2: 'Du generierst Chancen und gewinnst neue IT-Projekte.',
    job3_3: 'Du entwickelst selbstständig eigene Ideen, um unsere Zielkunden anzusprechen.',
    job3_4: 'Du hast eine ausgeprägte IT-Affinität und Erfahrungen im Vertrieb.',
    job3_5: 'Du bist ein Kommunikationstalent.',
    job3_6: 'Selbstständige, strukturierte und zuverlässige Arbeitsweise.',
    job3_7: 'Du sprichst fließend Deutsch und Englisch.',
    job3_8: 'Bereitschaft zur Vollzeittätigkeit.',
    job3_9: 'Wir bei AKNA sind ein IT-Beratungsunternehmen, ein junges Team aus top ausgebildeten und hochmotivierten Software Engineers aus Armenien. Unterstützt werden wir von unserem Partner R.U.D.A. Services GmbH. Seit knapp sieben Jahren bietet AKNA ein umfangreiches Spektrum an IT-Dienstleistungen in Deutschland, der Schweiz und Kanada an. Unsere Kunden bekommen hochqualitative Lösungen zu einem hervorragenden Preis-Leistungs-Verhältnis. Zur Verstärkung unseres Vertriebsteams suchen wir einen Sales-Manager in Jerewan (On-Site) oder in Deutschland (Remote).',
    job3_10: 'Senden Sie Ihre Bewerbung an eines der folgenden E-Mail-Adressen:',
    job3_11: 'Bitte geben Sie im Betreff den Namen der Position an: "Sales Manager".',

    gridbox_cv: 'Senden Sie bitte Ihren Lebenslauf an ',
    gridbox_mail: 'yelena@akna.tech ',
    share_job: 'Teilen Sie diesen Job',

    //human
    human_title: 'Personalabteilung',
    human_Firstname: 'Yelena Asilbekyan',
    human_name1: 'Human Resource Manager',
    human_img_text1:
      '“Don’t be afraid to get vulnerable for real answers and real growth.”',
    human_tel1: '+37495585500',
    human_email1: 'yelena@akna.tech',
    human_Firstname2: 'Narine Kharazyan',
    human_name2: 'Teamleiter Vertrieb',
    human_img_text2:
      '“Do the hard jobs first. The easy jobs will take care of themselves.”',
    human_tel2: '+49 (0)15678437527',
    human_email2: 'narine@akna.tech',
    img_text:
      'Wir unterstützen Sie beim Bewerbungsprozess und wenn Sie bei uns sind in Karrierefragen. Es ist uns wichtig, dass Sie und wir frühzeitig wissen, ob wir eine gemeinsame Zukunft haben. Deshalb sind wir bestrebt, Ihnen so schnell wie möglich ein Feedback zu Ihrer Bewerbung zu geben.',

    //Benefits
    benefits_title: 'DEINE BENEFITS',

    //Impressum
    impressum_overview:
      ' AKNA LLC, ein auf Softwareentwicklung spezialisiertes Unternehmen, wurde 2017 in Eriwan, Armenien, gegründet.',
    impressum_registration: 'Registrierungsnummer: 50550277',
    impressum_director: 'Geschäftsführer: Vardan Hovhannisyan',
    impressum_location: 'Adresse: Yekmalyan Str. 1, Yerevan 0001, Armenien',
    impressum_website: 'Webseite: www.akna.tech',
    impressum_email: 'E-Mail: info@akna.tech',

    cookies: 'Accept all cookies',
    preferences: 'Manage preferences',
    byClick:
      'By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyse site usage, and assist in our marketing and performance efforts.',
    privacy: 'We Value Your Privacy'
  },

  en: {
    // Above Top Menu
    aboutUs: 'Our Presence in Germany',

    // Top Menu Items
    offerings: 'Offerings',
    technology: 'Cloud native solutions',
    portfolio: 'Portfolio',
    iotSolutions: 'IoT Solutions',
    AiMl: 'AI/ML and Data',
    contactUs: 'Contact Us',
    imprint: 'Legal Notice',
    careers: 'Careers',

    tooltip: `switch language to German`,

    cookies: 'accept all cookies',
    preferences: 'manage preferences',
    byClick:
      'By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyse site usage, and assist in our marketing and performance efforts.',
    privacy: 'We Value Your Privacy',

    employers:
      '   AKNA employs software engineers with outstanding professional history and very demanded skills.',
    mountain_text1:
      'AKNA Employs Software Engineers With Outstanding Professional ',
    mountain_text2:
      'History And Very Demanded Skills.Young Developers Catch Up',
    mountain_text3: 'Swiftly By Learning From Seniors.',

    //SECTION 1: IoT Solutions
    solutions: 'SOLUTIONS',
    industry: 'We are building IoT and Industry 4.0 solutions.',
    point: 'We Connect Millions Of Devices And Billions Of Data Points.',
    text1:
      'For one of the leading global suppliers of premium and luxury cars we designed  platform.',
    text2:
      'For millions of Bluetooth connected consumer devices used across the world our team developed scalble and multi-tenant cloud based IoT platform. ',
    text3:
      'Major public transportation companies across Europe use IoT senors and software developed by our engineers.',
    text4:
      'For two Berlin based start-ups we are building solutions for equipment failure prediction and maintenance improvement based on real-time IoT data.',

    //TODO Add country translations in this form
    section1_country1: 'Germany',
    section1_country2: 'Switzerland',
    section1_country3: 'Germany',
    section1_country4: 'Germany',

    //SECTION 2: Cloud native solutions
    cloud: 'Cloud',
    native: ' Native Solutions',

    //TODO: add new translation item
    section2_subtitle: 'We support Azure, AWS and more technologies',

    //SECTION 3: AI and Data projects
    mlaisolutions: 'Our recent AI/ML and',
    mlaisolutions2: 'data projects',
    mlindustry:
      'We build optimized and robust data platforms which ensure quality, streamline discovery and enable data driven products.',
    mltext1:
      'Our engineers develop Azure based business intelligence platform for one of the world’s leading medical technology companies. They build it with Azure DataBricks, Synapse, PowerApps, Data Lake Gen2, PowerBI and more.',
    mltext2:
      'Since two years we co-developed enterprise knowledge Graph software solution for one innovaive German vendors. The software provides custom and flexible user interface for modeling and mapping.',
    mltext3:
      'We support development of a core platform providing VR and AR services and 3D modeling tools for a standing out VR company.',
    mltext4:
      'Our engineers developed big data and advanced analytics solution for price optimization and targeted marketing for telecom company.',

    //TODO Add country translations in this form
    section3_country1: 'Switzerland',
    section3_country2: 'Germany',
    section3_country3: 'Germany',
    section3_country4: 'Bahamas',

    country: 'Country',

    germany: 'Germany',
    armenia: 'Armenia',
    switzerland: 'Switzerland',
    bahamas: 'Bahamas',
    text: 'IoT senors hardware and software development for urban mobility solutions. Used by public transportation companies across the Europe.',

    // clients
    clients_title: 'FEW OF OUR CLIENTS',
    clients_text:
      'Our experience and credibility comes from the delivered multiple solutions, teams and products.',

    //our team
    our_team: 'our team',
    members: 'members',
    we_speak_German: 'we speak german',
    hover0: 'Co-Founder and Cloud Engineer',
    hover1: '.NET Core and Azure Developer',
    hover2: 'Python and AWS Developer',

    //available profiles
    skills: 'Skills',
    projects: 'Projects',
    experience: 'Experience',
    available_consultants: 'AVAILABLE CONSULTANTS',
    available_experts: 'Currently available experts for your project.',

    //our location
    our_location_title: 'OUR LOCATIONS',
    we_support: 'we sUPPORT EVERYWHERE',

    //carrers
    be_a_part_of_us: 'BE A PART OF US',
    support_everyone: 'WE SUPPORT EVERYONE',
    careers_description:
      ' At Akna Tech everyone assumes responsibility and thus contributes to our success. Our work is agile and flexible. Joining Akna Tech can work on inovative projects of worldwide leading company and scaling startups.',

    //TO-DO: clean-up unused texts and add list of jobs
    //AccordionBox
    your_benefits: 'Your benefits:',
    your_qualification: 'Your qualification:',
    your_contribution: 'Your contribution:',
    //job1
    job1_1:
      'Support and further development of the cloud-based system landscape',
    job1_2:
      'Ensuring the efficiency and effectiveness of applications in Azure environment through automation and standardization',
    job1_3: 'Automating the development and deployment processes',
    job1_4:
      'Designing, deploying, and improving solutions for running containers',
    job1_5: 'Deploy test automation tools',
    job1_6:
      'Relevant professional experience and good application knowledge of the Azure Cloud',
    job1_7: 'Proficient in the use of containers and Kubernetes',
    job1_8: 'Proficiency in .NET ',
    job1_9: 'Knowledge of development processes (CI/CD)',
    job1_10:
      'Good knowledge of Linux, virtualization and databases (e.g. MS SQL, MySQL or Maria DB)',
    job1_11:
      'At best, you have certifications for Azure (e.g. Azure Developer, DevOps Engineer, Azure Solutions Architect) or Kubernetes',
    job1_12: 'Good communication skills ',
    job1_13: 'Very good English skills ',
    job1_14:
      'Application of state-of-the-art technologies in a powerful development environment ',
    job1_15: 'An appreciative and collaborative corporate culture ',
    job1_16: 'Flexibility in work arrangements ',
    job1_17: 'Regular training and development opportunities ',
    job1_18:
      'Flat hierarchies with open doors in a relaxed but professional atmosphere ',
    //job2
    job2_1: 'Schedule projects',
    job2_2: 'Conceptualize visuals based on requirements',
    job2_3: 'Prepare rough drafts and present ideas',
    job2_4:
      'Develop illustrations, storyboards, and other designs using software or by hand',
    job2_5: 'Use the appropriate colors and layouts for each graphic',
    job2_6: 'Test graphics across various media',
    job2_7: 'Passionate about graphic design',
    job2_8:
      'Familiarity with design software and technologies (such as InDesign, Illustrator, Photoshop)',
    //job_3
    job3_1: 'You are responsible for establishing and continuously maintaining customer contacts.',
    job3_2: 'You generate opportunities and win new IT projects.',
    job3_3: 'You come up with your own ideas to reach our target customers.',
    job3_4: 'You have a strong IT affinity and experience in sales.',
    job3_5: 'You have a talent for communication.',
    job3_6: 'Independent, structured and reliable way of working.',
    job3_7: 'You speak German and English fluently.',
    job3_8: 'Willingness to work full-time.',
    job3_9: 'We at AKNA are an IT consulting company, a young team of highly trained and highly motivated software engineers from Armenia. We are supported by our partner R.U.D.A. Services GmbH. AKNA has been offering a comprehensive range of IT services in Germany, Switzerland and Canada for almost seven years. Our customers receive high-quality solutions at an excellent price-performance ratio. To strengthen our sales team, we are looking for a sales manager in Yerevan (on-site) or in Germany (remote).',
    job3_10: 'Send your application to one of the following email addresses: ',
    job3_11: 'Please mention the name of the position in the subject line: “Sales Manager”.',

    gridbox_cv: 'Please send your CV to ',
    gridbox_mail: 'yelena@akna.tech ',
    share_job: 'share this job with',

    //human
    human_title: 'Human Resources',
    human_Firstname: 'Yelena Asilbekyan',
    human_name1: 'Human Resource Manager',
    human_img_text1:
      '“Don’t be afraid to get vulnerable for real answers and real growth.”',
    human_tel1: '+37495585500',
    human_email1: 'yelena@akna.tech',
    human_Firstname2: 'Narine Kharazyan',
    human_name2: 'Team Lead Sales',
    human_img_text2:
      '“Do the hard jobs first. The easy jobs will take care of themselves.”',
    human_tel2: '+49 (0)15678437527',
    human_email2: 'narine@akna.tech',
    img_text:
      "We support you with the application process and when you are with us in career questions. It is important to us that you and we know early  on if we have a common future. Therefore, we're eager to giving you feedback on your application as quicky as possible.",

    //Benefits
    benefits_title: 'YOUR BENEFITS',

    //Impressum
    impressum_overview:
      ' Software engineering company AKNA LLC was incorporated in Yerevan, Armenia in 2017.',
    impressum_registration: ' Company registration number: 50550277.',
    impressum_director: ' Director: Vardan Hovhannisyan.',
    impressum_location:
      ' Premises are located at 1 Yekmalyan St., Yerevan 0001, Armenia.',
    impressum_website: 'Website: www.akna.tech',
    impressum_email: 'E-Mail: info@akna.tech',

    about_text1: 'Company',
    about_text2: 'WHO WE ARE',
    about_text3: 'OUR TEAM',
    about_text4:
      'AKNA is an Armenia based data and technology company founded in 2017. We provide solutions for advanced tech challenges such as Cloud and Big Data, staff augmentation and team extensions from Eastern European countries. AKNA is partnered with several German and international companies.',
    about_text5: 'OUR MISSION',
    about_text6:
      'Our Mission is to be a leading IT company and progress in our current position in market. Customer’s growth is our growth, so we commit our customers to help in achieving their business goals. We want to be known as the reliable and innovative software service provider in IT industry.',
    about_text7: 'OUR VALUES',
    about_text8:
      'Our main purpose as a company is to bring great products to the planet. We value simplicity and avoid complexity, be it in hardware or software. It’s important to us that employees treat one another with mutual respect to foster a healthy and open working environment. ',
    about_text9: 'Vardan Hovhannisyan',
    about_text10: 'Co-Founder and Cloud Engineer',
    about_text11: 'Hovhannes Torosyan',
    about_text12: '.NET Core And Azure Developer'
  },
  ar: {
    // Above Top Menu
    aboutUs: 'تواجدنا في ألمانيا',

    // Top Menu Items
    offerings: 'عروض',
    technology: 'حلول سحابيةأصلية',
    portfolio: 'محفظة',
    iotSolutions: 'حلول الإنترنت الأشيائية',
    AiMl: 'الذكاء الاصطناعي/التعلم الآلي و البيانات',
    contactUs: 'تواصل معنا',
    imprint: 'إشعار قانوني',
    careers: 'مهن',

    tooltip: `تغيير اللغة إلى اللغة الألمانية`,

    cookies: 'accept all cookies',
    preferences: 'إدارة التفضيلات',
    byClick:
      ' .أنت توافق على تخزين ملفات تعريف الارتباط على جهازك لتحسين التنقل في الموقع و تحليل استخدام الموقع، والمساعدة في جهودنا التسويقية والأداءية، accept all cookies بانقر على ',

    privacy: 'نحن نُقدّر خصوصيتنا',

    employers:
      'توظف مهندسي برامج ذو تاريخ اختصاصي متميّز و مهارات مطلوبة كثيراً AKNA',
    mountain_text1: 'توظف مهندسي برامج ذو تاريخ اختصاصي متميّز AKNA',
    mountain_text2: 'و مهارات مطلوبة كثيراً. يلحق الشبان المطورين',
    mountain_text3: 'بسرعة عن طريق التعلم من الكبار في الخبرة',

    //SECTION 1: IoT Solutions
    solutions: 'حلول',
    industry: 'نحن نبني حلول باستخدام الإنترنت الأشيائية و الصناعة 4.0',
    point:
      'نحن نربط الملايين من الأجهزة والبلايين من نقاط البيانات مع بعضها البعض',
    text1:
      '.صممنا منصة لإحدى مورّدي السبارات الاستثنائية والفاخرة الرائدة عالمياً',
    text2:
      '.قام فريقنا بتطوير منصة إنترنت الأشياء السحابية القابلة للتطوير و المتعددة المستأجرين للملايين من الأجهزة الاستهلاكية المرتبطة مع البلوتوث و المستخدمة في كل أنحاء العالم',
    text3:
      '.يستخدم الكثير من شركات وسائل النقل العامة الكبيرة عبر أوروبا مستشعرات إنترنت الأشياء والبرمجة المطوّرة من قبل مهندسينا',
    text4:
      '.نحن الآن نقوم ببناء حلول لتوقع فشل الأجهزة و تطوير الصيانة على  أساس بيانات إنترنت الأشياء الحالي لشركتين مبتدئتين في برلين',

    //TODO Add country translations in this form
    section1_country1: 'ألمانيا',
    section1_country2: 'سويسرا',
    section1_country3: 'ألمانيا',
    section1_country4: 'ألمانيا',

    //SECTION 2: Cloud native solutions
    cloud: 'سحابة',
    native: 'حلول أصلية',

    //TODO: add new translation item
    section2_subtitle: 'و تقنيات أخرى AWS و Azure نحن ندعم',

    //SECTION 3: AI and Data projects
    mlaisolutions:
      'أحدث مشاريعنا المتعلقة بالذكاء الاصطناعي و التعلُّم الآلي و',
    mlaisolutions2: 'مشاريع البيانات',
    mlindustry:
      '.نحن نقوم ببناء منصات بيانات محدّثة وقويّة التي تضمن الجودة،تبسّط الاكتشاف و تمكّن المنتجات القائمة على البيانات',
    mltext1:
      '.PowerBI ،Data Lake Gen2 ،PowerApps ،Synapse ،Azure DataBricks لإحدى شركات تكنولوجيا الطبية الرائدة في العالم هم بنوها باستخدام  Azure مهندسينا يطورون منصة ذكاء أعمال على أساس',
    mltext2:
      '.منذ عامين قمنا بتطوير مشترك لحلاً برمجيًا لرسم البيانات الشاملة لإحدى الشركات الألمانية المبتكرة. يوفر البرنامج واجهة مستخدم مخصصة وقابلة للتعديل لنمذجة البيانات ورسمها',
    mltext3:
      '.نحن ندعم تطوير منصة أساسية توفر خدمات الواقع الافتراضي والواقع المعزز وأدوات النمذجة ثلاثية الأبعاد لشركة واقع افتراضي متميزة',
    mltext4:
      '.مهندسينا طوروا بيانات كبيرة و حل تحليلي متقدم لتحسين الأسعار والتسويق المستهدف لشركة الاتصالات',
    //TODO Add country translations in this form
    section3_country1: 'سويسرا',
    section3_country2: 'ألمانيا',
    section3_country3: 'ألمانيا',
    section3_country4: 'جزر البهاما',

    country: 'دولة',

    germany: 'ألمانيا',
    armenia: 'أرمينيا',
    switzerland: 'سويسرا',
    bahamas: 'جزر البهاما',
    text: '.تعمل أجهزة إنترنت الأشياء على تطوير الأجهزة والبرمجيات لحلول التنقل في المناطق الحضرية. تستخدم من قبل شركات النقل العام في جميع أنحاء أوروبا ',

    // clients
    clients_title: 'البعض من زبائننا',
    clients_text:
      '.خبرتنا و مصداقيتنا تأتي من خلال تسليم حلول متعددة،فرائق و منتجات',

    //our team
    our_team: 'فريقنا',
    members: 'الأعضاء',
    we_speak_German: 'نحن نتكلم الآلمانية',
    hover0: 'المؤسس المشارك ومهندس السحابة',
    hover1: 'Azure و .NET Core مبرمج',
    hover2: 'AWS و Python مبرمج',

    //available profiles
    skills: 'مهارات',
    projects: 'مشاريع',
    experience: 'خبرة',
    available_consultants: 'الاستشاريون المتاحون',
    available_experts: 'الخبراء المتاحين حاليا لمشروعك',

    //our location
    our_location_title: 'مواقعنا',
    we_support: 'نحن ندعم كل مكان',

    //carrers
    be_a_part_of_us: 'كن جزءاً منا',
    support_everyone: 'نحن ندعم الكل',
    careers_description:
      ' .AKNA Tech  الكل قادر على تحمٌّل المسؤولية و بالتالي يساهمون في نجاح الشركة. طريقة عملنا تعد سريعة و مرنة. بالانضمام إلينا سوف  تعمل على مشاريع مبتكرة لشركات رائدة عالميًا و سوف تشارك في توسيع نطاق الشركات الناشئة AKNA Tech في ',

    //TO-DO: clean-up unused texts and add list of jobs
    //AccordionBox
    your_benefits: ':المنافع الخاصة بك',
    your_qualification: ':مؤهلاتك',
    your_contribution: ':مساهمتك',
    //job1
    job1_1: 'دعم ومواصلة تطوير مشهد النظام القائم على السحابة',
    job1_2:
      'ضمان كفاءة وفعالية التطبيقات في بيئة Azure من خلال الأتمتة والتوحيد القياسي',
    job1_3: 'أتمتة التطوير و عمليات النشر',
    job1_4: 'تصميم، نشر و تحسين الحلول للحاويات الجارية',
    job1_5: 'نشر أدوات اختبار الأتمتة',
    job1_6: 'Azure Cloud الخبرة المهنية ذات الصلة ومعرفة التطبيق الجيدة لـ',
    job1_7: 'Kubernetes يتقن استخدام الحاويات و',
    job1_8: '.NET الكفاءة في',
    job1_9: '(CI/CD) معرفة تطوير عمليات',
    job1_10: ' معرفة جيدة بنظام',
    job1_11:
      ' Kubernetes أو (Azure Developer أو DevOps Engineer أو Azure Solutions Architect على سبيل المثال) Azure في أحسن الأحوال، أن يكون لديك شهادات ل',
    job1_12: 'مهارات تواصل جيدة',
    job1_13: 'مهارات جيدة جداً في اللغة الانكليزية',
    job1_14: 'تطبيق أحدث التقنيات في بيئة تطوير قوية',
    job1_15: 'ثقافة مؤسسية تقديرية وتعاونية ',
    job1_16: 'مرونة في ترتيبات العمل',
    job1_17: 'فرص تطوُّر و تدريب منتظم',
    job1_18: 'تسلسلات هرمية مسطحة بأبواب مفتوحة في جو مريح و احترافي ',
    //job2
    job2_1: 'جدولة المشاريع',
    job2_2: 'تصور العناصر المرئية بناءً على المتطلبات',
    job2_3: 'إعداد المسودات التقريبية وتقديم الأفكار',
    job2_4:
      'قم بتطوير الرسوم التوضيحية والقصص المصورة والتصميمات الأخرى باستخدام البرامج أو يدويًا',
    job2_5: 'استخدم الألوان والتخطيطات المناسبة لكل رسم',
    job2_6: 'اختبار الرسومات عبر الوسائط المختلفة',
    job2_7: 'شغوف بالتصميم الجرافيكي',
    job2_8:
      '(InDesign و Illustrator و Photoshop مثل) الإلمام ببرامج وتقنيات التصميم',
    //TODO add arabic translation for sales position
    //job_3
    job3_1: 'You are responsible for establishing and continuously maintaining customer contacts.',
    job3_2: 'You generate opportunities and win new IT projects.',
    job3_3: 'You come up with your own ideas to reach our target customers.',
    job3_4: 'You have a strong IT affinity and experience in sales.',
    job3_5: 'You have a talent for communication.',
    job3_6: 'Independent, structured and reliable way of working.',
    job3_7: 'You speak German and English fluently.',
    job3_8: 'Willingness to work full-time.',
    job3_9: 'We at AKNA are an IT consulting company, a young team of highly trained and highly motivated software engineers from Armenia. We are supported by our partner R.U.D.A. Services GmbH. AKNA has been offering a comprehensive range of IT services in Germany, Switzerland and Canada for almost seven years. Our customers receive high-quality solutions at an excellent price-performance ratio. To strengthen our sales team, we are looking for a sales manager in Yerevan (on-site) or in Germany (remote).',
    job3_10: 'Send your application to one of the following email addresses: ',
    job3_11: 'Please mention the name of the position in the subject line: “Sales Manager”.',

    gridbox_cv: 'يرجى إرسال سيرتك الذاتية إلى ',
    gridbox_mail: 'yelena@akna.tech ',
    share_job: 'شارك هذه المهنة مع',

    //human
    human_title: 'الموارد البشرية',
    human_Firstname: 'إلينا أسيلبكيان',
    human_name1: 'مديرة الموارد البشرية',
    human_img_text1: '“.لا تخف من التعرض للإجابات الحقيقية والنمو الحقيقي”',
    human_tel1: '+37495585500',
    human_email1: 'yelena@akna.tech',
    human_Firstname2: 'نارينيه خازاريان',
    human_name2: 'رئيسة فريق المبيعات',
    human_img_text2:
      '".قم بالمهام الصعبة أولاً. فإن المهام السهلة سوف تعتني بنفسها"',
    human_tel2: '+49 (0)15678437527',
    human_email2: 'narine@akna.tech',
    img_text:
      'سوف ندعمكم في عمليات الطلب و نخبركم عن موعد المقابلة لطرح الأسئلة المتعلقة بالمهنة. إنَّه ضروري بالنسبة لنا أن نعرف في وقتٍ مبكر إذا ما كان لدينا مستقبل مشترك أم لا. لذلك نحن حريصين على أن نرد التعليق الملائم لطلبكم في أسرع وقت ممكن ',

    //Benefits
    benefits_title: 'المنافع الخاصة بك',

    //Impressum
    impressum_overview:
      ' في يرفان عاصمة أرمينيا عام2017 AKNA LLC تأسست شركة هندسة البرمجيات ',
    impressum_registration: ' رقم تسجيل الشركة: 50550277',
    impressum_director: 'المدير: فاردان هوفهانيسيان',
    impressum_location: '.المباني تقع في شارع يكماليان 1، يريفان 0001، أرمينيا',
    impressum_website: 'Website: www.akna.tech',
    impressum_email: 'E-Mail: info@akna.tech',

    about_text1: 'شركة',
    about_text2: 'من نحن؟',
    about_text3: 'فريقنا',
    about_text4:
      '.لديها الشراكة مع العديد من الشركات الألمانية والعالمية AKNA .شركة تكنولوجيا و بيانات مقرها أرمينيا تأسست عام2017. نحن نقدم حلولاً للتحديات التقنية المتقدمة مثل السحابة والبيانات الضخمة وزيادة الموظفين و توسيع الفرق من دول أوروبا الشرقية AKNA تعد',
    about_text5: 'مهمتنا',
    about_text6:
      'مهمتننا أن نكون شركة تكنولوجيا معلومات رائدة و أن نتقدم في مركزنا الحالي في السوق. نمو العملاء هو نموّنا، فنحن نلتزم بعملائنا من خلال تقديم الساعدة لهم في تحقيق أهدافهم المهنية.نحن نريد أن نكون معروفين كمؤمّني خدمة برمجيات موثوقة و مبتكرة في مجال تكنولوجيا المعلومات ',
    about_text7: 'قيمنا',
    about_text8:
      '.غايتنا الأساسية كشركة تقديم منتجات عظيمة للعالم.نحن نقدر البساطة و نحاول الابتعاد عن التعقيد،سواء كان ذلك في البرامج أو الأجهزة.الاحترام المتبادل ضروريّة بالنسبة لنا وذلك لتعزيز بيئة عمل مفتوحة صحية ',
    about_text9: 'فاردان هوفهانيسيان',
    about_text10: 'المؤسس المشارك ومهندس السحابة',
    about_text11: 'هوفهانيس توروسيان',
    about_text12: 'Azure و .NET Core مبرمج'
  }
};

export default translation;
