import React from 'react';

const Clutch = () => (
  <div
    className='clutch-widget'
    data-url='https://widget.clutch.co'
    data-widget-type='1'
    data-height='40'
    data-clutchcompany-id='1883311'
  />
);

export default Clutch;
