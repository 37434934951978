const vacancies = (tr) => ({
  ger: [],
  en: [
    {
      id: 1,
      title: 'IT Sales Manager',
      description: tr.job3_9,
      contribution: [tr.job3_1, tr.job3_2, tr.job3_3],
      qualification: [
        tr.job3_4,
        tr.job3_5,
        tr.job3_6,
        tr.job3_7,
        tr.job3_8
      ],
      applicationDetails: [tr.job3_10, tr.job3_11]
    },
    {
      id: 2,
      title: 'DevOps Engineer – Azure',
      benefits: [tr.job1_14, tr.job1_15, tr.job1_16, tr.job1_17, tr.job1_18],
      contribution: [tr.job1_1, tr.job1_2, tr.job1_3, tr.job1_4, tr.job1_5],
      qualification: [
        tr.job1_6,
        tr.job1_7,
        tr.job1_8,
        tr.job1_9,
        tr.job1_10,
        tr.job1_11,
        tr.job1_12,
        tr.job1_13
      ]
    }
  ]
});

export default vacancies;
