const consultants = [
  {
    id: 1,
    position: 'Lead DevOps Engineer',
    skills:
      'Azure (AKS, Synapse, Data Factory, Databricks, Purview, Data Lake Storage Gen2, Container services), Azure DevOps, Terraform, CloudFoundry, Jenkins, Helm, Kubernetes, Ansible',
    comments: 'Eurowings, Mercedes Benz',
    experience: '10 years',
    isPublic: true,
    img: 'Images/development1.webp'
  },
  {
    id: 2,
    position: 'Senior Azure Consultant',
    skills:
      'Azure (Databricks, Data Lake, Big Data, Data Storage, Stream Analytics, Synapse, SQL) Power BI, Power Apps, Power Automate',
    comments: 'Stryker AG (Switzerland)',
    experience: '8 years',
    isPublic: true,
    img: 'Images/development2.jpg'
  },
  {
    id: 3,
    position: 'Senior Vuejs Developer',
    skills: 'Vue.js Framework, Nuxt.js, Typescript, Azure Cloud, Docker',
    comments: 'T-Systems',
    experience: '5 years',
    isPublic: true,
    img: 'Images/development3.jpg'
  }
];

export default consultants;
