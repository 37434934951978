import React from 'react';
import Icon from '../icon';
import Clutch from '../clutch/clutch';
import socialMedia from '../../constants/socialMedia';
import footerLinks from '../../constants/footerLinks';
import './style.scss';

const Footer = ({ tr }) => {
  const currentYear = new Date().getFullYear();

  return (
    <div className='footer_container'>
      <div className='container footer_content'>
        <div className='footer_rights'>
          <span className='copyright'>
            © {currentYear} Akna LLC. All Rights Reserved
          </span>
        </div>
        <div className='footer_links_container'>
          <Clutch />
          {socialMedia.map((item) => (
            <div key={item.id}>
              <a rel='noreferrer' target='_blank' href={item.href}>
                <Icon id={item.iconId} />
              </a>
            </div>
          ))}
        </div>

        <div className='footer'>
          {footerLinks(tr).map((item) => (
            <a key={item.id} href={item.href}>
              {item.text}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
