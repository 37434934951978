import React from 'react';
import './style.scss';

const TitleText = ({ children, className }) => (
  <span className={'title_text' + `${className ? className : ''}`}>
    {children}
  </span>
);

export default TitleText;
