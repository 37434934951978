const languages = [
  {
    ref: 'de',
    name: 'DE'
  },
  {
    ref: 'en',
    name: 'EN'
  },
  {
    ref: 'ar',
    name: 'العربية'
  }
];

export default languages;
