import { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Homepage from '../Homepage';
import translation from '../../translation';
import Carrers from '../carrers';
import useLocale from '../../hooks/useLocale';
import useGeolocation from '../../hooks/useGeolocation';
import Impressum from '../impressum/impressum';
import languages from '../../constants/languages';
import './App.css';
import '../styles/global.scss';

const App = () => {
  const { getLocale, saveLocale } = useLocale();
  const locale = getLocale();
  const { countryCode } = useGeolocation();

  useEffect(() => {
    if (countryCode) {
      const isMatch = languages.some(
        (language) => language.ref === countryCode
      );

      saveLocale(isMatch ? countryCode : 'en');
    }
  }, [countryCode]);

  const tr = translation[locale];

  return (
    <div className='App'>
      <Router>
        <Switch>
          <Route path='/' exact render={() => <Homepage tr={tr} />} />
          <Route path='/carrers' exact render={() => <Carrers tr={tr} />} />
          <Route path='/imprint' exact render={() => <Impressum tr={tr} />} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
