const socialMedia = [
  {
    id: 1,
    href: 'https://www.facebook.com/aknatech',
    iconId: 'fb'
  },
  {
    id: 2,
    href: 'https://www.linkedin.com/company/aknatech/',
    iconId: 'linked'
  }
];

export default socialMedia;
