import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Icon from '../icon';
import useLocale from '../../hooks/useLocale';
import translation from '../../translation';
import LanguageDropdown from '../languageDropdown/languageDropdown';
import './style.scss';

const Header = (props) => {
  const [active, setActive] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  let { impressum } = props;

  if (!impressum) {
    impressum = false;
  }

  const { getLocale } = useLocale();
  const locale = getLocale();

  const tr = translation[locale];

  return (
    <header>
      <div className='ruda'>
        <p className='ger'>{tr.aboutUs}</p>
        <a
          style={{ marginLeft: '-23px' }}
          target='_blank'
          rel='noreferrer'
          href='https://ruda-services.com/'
        >
          <img src='/Images/rudaLogo.png' alt='ruda' width='28' height='27' />
        </a>{' '}
      </div>
      <hr />
      <div className={`navbar ${active ? 'active' : ''}`}>
        <a href='/'>
          <Icon id='aknaLogo' />
        </a>

        <div>
          <div
            onClick={() => setActive((val) => !val)}
            className={`burger ${active ? 'active' : ''}`}
          >
            <Icon id='line' />
            <Icon id='line' />
          </div>

          <ul className={`menu ${active ? 'active' : ''}`}>
            <div className='dropdown'>
              <li className='menu-item dropbtn'>
                <a
                  style={isMobile ? { paddingLeft: '60px' } : {}}
                  href='/#iot-solutions'
                >
                  {tr.offerings}
                </a>
                <div className='dropdown-content'>
                  <a href='/#iot-solutions'>{tr.iotSolutions}</a>
                  <a href='/#technology'>{tr.technology}</a>
                  <a href='/#Ai/Ml'>{tr.AiMl}</a>
                </div>
              </li>
            </div>

            <li className='menu-item'>
              <a href='/#clients'>{tr.portfolio}</a>
            </li>

            <li className='menu-item'>
              <a href='/#our-location'>{tr.contactUs}</a>
            </li>

            <li className='menu-item'>
              <a href='/imprint'>{tr.imprint}</a>
            </li>
            <li className='menu-item'>
              <a href='/carrers'>{tr.careers}</a>
            </li>
            <LanguageDropdown />
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
