import React from 'react';
import useLocale from '../../hooks/useLocale';
import languages from '../../constants/languages';
import './style.scss';

const LanguageDropdown = () => {
  const { saveLocale, getLocale } = useLocale();
  const locale = getLocale();

  const langChange = (value) => {
    saveLocale(value);
    window.location.reload();
  };

  return (
    <li className='language_dropdown menu-item tooltip'>
      <span>{languages.find((el) => el.ref === locale).name}</span>

      <i className='fa fa-angle-down' aria-hidden='true' />
      <div className='triangle' />
      <ul className='language_dropdown_list'>
        {languages.map((item) =>
          item.ref !== locale ? (
            <li key={item.name} onClick={() => langChange(item.ref)}>
              <span className='active'>{item.name}</span>
            </li>
          ) : null
        )}
      </ul>
    </li>
  );
};
export default LanguageDropdown;
