import Header from '../header';
import React from 'react';
import './impressum.scss';
import Footer from '../footer/footer';

const Impressum = ({ tr }) => (
  <div className='impressum'>
    <div>
      <Header impressum />
      <div className='impressum_header'>
        <h1 className='impressum_title'>{tr.imprint}</h1>
      </div>
      <div className='cont'>
        {tr.impressum_overview}
        <br />
        <a
          className='tumo'
          rel='noreferrer'
          target='_blank'
          href='https://www.e-register.am/en/companies/1117605'
        >
          {' '}
          {tr.impressum_registration}{' '}
        </a>
        <br />
        {tr.impressum_director}
        <br />
        {tr.impressum_location}
        <br />
        <a
          className='tumo'
          target='_blank'
          rel='noreferrer'
          href='http://akna.tech'
        >
          {tr.impressum_website}
        </a>
        <br />
        <a className='tumo' href='mailto:contact@akna.tech'>
          {tr.impressum_email}
        </a>
      </div>
    </div>
    <Footer tr={tr} />
  </div>
);

export default Impressum;
