import React from 'react';
import { useMediaQuery } from 'react-responsive';
import './style.scss';

const InfoCard = ({ data }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 500px)` });

  return (
    <div className={`vacancies_info_box  vacancies_info_box_${data.title}`}>
      <div className='layer'>
        <h1>{data.text}</h1>
        <div className='vacancies_info'>
          {data.logo(isMobile)}
          <div>
            {data.infoLinks.map((el) => (
              <a
                key={el.link}
                href={el.link}
                target={el.target ? '_blank' : '_self'}
                rel='noreferrer'
              >
                {el.text}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
