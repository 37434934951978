import React from 'react';
import PartOfUs from '../partOfUs';
import Human from '../human';
import useLocale from '../../hooks/useLocale';
import translation from '../../translation';
import Header from '../header';
import './style.scss';

const Carrers = () => {
  const { getLocale } = useLocale();
  const locale = getLocale();
  const tr = translation[locale];

  return (
    <div className='carrers_container'>
      <div className='content'>
        <Header />
        <PartOfUs tr={tr} />
        <Human tr={tr} />
      </div>
    </div>
  );
};

export default Carrers;
