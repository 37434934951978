import React from 'react';
import AccordionBox from '../AccordionBox';
import Title from '../Title';
import TitleText from '../TitleText';
import InfoCard from '../InfoCard';
import infoData from '../../constants/infoData';
import './style.scss';

const PartOfUs = ({ tr }) => (
  <div className='container vacancies'>
    <div className='vacancies_content'>
      <Title className='title_lg' size={72} color='#1dc273'>
        {tr.be_a_part_of_us}
      </Title>
      <TitleText className=' title_desc'>{tr.careers_description}</TitleText>

      <div className='vacancies_box'>
        {infoData.map((item) => (
          <div key={item.country}>
            <InfoCard data={item} />
            <AccordionBox tr={tr} country={item.country} />
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default PartOfUs;
