import React from 'react';
import Title from '../Title';
import Footer from '../footer/footer';
import humanData from '../../constants/human';
import './style.scss';

const Human = ({ tr }) => (
  <div className='human_container'>
    <h1 className='container human_title'>{tr.human_title}</h1>
    <div className='humans'>
      {humanData(tr).map((item) => (
        <div key={item.id}>
          {item.imgSrc ? (
            <div
              className='info_container'
              style={{ backgroundImage: `url(${item.imgSrc})` }}
            />
          ) : (
            <div
              className='info_container'
              style={{ backgroundImage: `url(${item.img})` }}
            >
              <div className='layer'>
                <div className='info'>
                  <div className='human_info'>
                    <span className='human_info_firstname'>
                      {item.firstname}
                    </span>
                    <span className='human_info_name'>{item.name}</span>
                    <span className='human_info_text'>{item.text}</span>
                  </div>
                  <div className='human_info_contacts'>
                    <div>
                      Tel. {item.tel} <br />
                      Email: {item.email}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>

    <div className='support_container'>
      <Title className='title_lg' color='#fff' highlighted size={70}>
        {tr.support_everyone}
      </Title>
      <div className='support'>
        <div className='support_img'>
          <div className='support_layer'>
            <span className='support_text'>{tr.img_text}</span>
          </div>
        </div>
      </div>
    </div>
    <Footer tr={tr} />
  </div>
);

export default Human;
