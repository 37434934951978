import React, { memo } from 'react';
import { ReactComponent as databrick } from '../../images/databrick.svg';
import { ReactComponent as datalake } from '../../images/datalake.svg';
import { ReactComponent as stream } from '../../images/stream.svg';
import { ReactComponent as datafactory } from '../../images/datafactory.svg';
import { ReactComponent as azure } from '../../images/azure.svg';
import { ReactComponent as machine } from '../../images/machine.svg';
import { ReactComponent as instances } from '../../images/instances.svg';
import { ReactComponent as factory } from '../../images/factory.svg';
import { ReactComponent as fabric } from '../../images/fabric.svg';
import { ReactComponent as registry } from '../../images/registry.svg';
import { ReactComponent as power } from '../../images/power.svg';
import { ReactComponent as softvare } from '../../images/softvare.svg';
import { ReactComponent as d1 } from '../../images/3_1.svg';
import { ReactComponent as d2 } from '../../images/3_2.svg';
import { ReactComponent as d3 } from '../../images/3_3.svg';
import { ReactComponent as post } from '../../images/post.svg';
import { ReactComponent as sql } from '../../images/sql.svg';
import { ReactComponent as cosmos } from '../../images/cosmos.svg';
import { ReactComponent as firewall } from '../../images/firewall.svg';
import { ReactComponent as maria } from '../../images/maria.svg';
import { ReactComponent as redis } from '../../images/redis.svg';
import { ReactComponent as s1 } from '../../images/s1.svg';
import { ReactComponent as s2 } from '../../images/s2.svg';
import { ReactComponent as logic } from '../../images/logic.svg';
import { ReactComponent as bus } from '../../images/bus.svg';
import { ReactComponent as eventS } from '../../images/event.svg';
import { ReactComponent as api } from '../../images/api.svg';
import { ReactComponent as l1 } from '../../images/l1.svg';
import { ReactComponent as l2 } from '../../images/l2.svg';
import { ReactComponent as l3 } from '../../images/l3.svg';
import { ReactComponent as l4 } from '../../images/l4.svg';
import { ReactComponent as vector } from '../../images/vector.svg';
import { ReactComponent as solution } from '../../images/solution.svg';
import { ReactComponent as gitlab } from '../../images/gitlab.svg';
import { ReactComponent as bamboo } from '../../images/bamboo1.svg';
import { ReactComponent as jenkins } from '../../images/jenkins.svg';
import { ReactComponent as aws } from '../../images/aws.svg';
import { ReactComponent as test } from '../../images/test.svg';
import { ReactComponent as k } from '../../images/k.svg';
import { ReactComponent as fargate } from '../../images/fargate.svg';
import { ReactComponent as git } from '../../images/git.svg';
import { ReactComponent as hube } from '../../images/hube.svg';
import { ReactComponent as bitbucket } from '../../images/bitbucket.svg';
import { ReactComponent as we } from '../../images/we.svg';
import { ReactComponent as ruda } from '../../images/ruda.svg';
import { ReactComponent as rights } from '../../images/rights.svg';
import { ReactComponent as fb } from '../../images/fb.svg';
import { ReactComponent as linked } from '../../images/in.svg';
import { ReactComponent as akna } from '../../images/akna.svg';
import { ReactComponent as rudaLogo } from '../../images/rudaLogo.svg';
import { ReactComponent as aknaLogo } from '../../images/aknaLogo.svg';
import { ReactComponent as line } from '../../images/line.svg';
import { ReactComponent as AKNALOGORIGHTS } from '../../images/AKNALOGORIGHTS.svg';
import { ReactComponent as RUDARIGHTS } from '../../images/RUDARIGHTS.svg';
import { ReactComponent as Facebook } from '../../images/Facebook.svg';
import { ReactComponent as Linkedin } from '../../images/Linkedin.svg';
import { ReactComponent as ar } from '../../images/ar.svg';
import { ReactComponent as awselastic } from '../../images/awselas.svg';
import { ReactComponent as awselasdark } from '../../images/awselasdark.svg';
import { ReactComponent as awscont } from '../../images/awccont.svg';
import { ReactComponent as amazon } from '../../images/amazon.svg';
import { ReactComponent as awsfar } from '../../images/awsfar.svg';

const components = {
  awsfar,
  amazon,
  awscont,
  awselasdark,
  awselastic,
  ar,
  aknaLogo,
  line,
  Linkedin,
  Facebook,
  rudaLogo,
  akna,
  fb,
  linked,
  rights,
  AKNALOGORIGHTS,
  RUDARIGHTS,
  ruda,
  bitbucket,
  we,
  git,
  hube,
  aws,
  fargate,
  k,
  test,
  l1,
  gitlab,
  bamboo,
  jenkins,
  l2,
  solution,
  l3,
  l4,
  eventS,
  bus,
  api,
  logic,
  s1,
  s2,
  firewall,
  maria,
  vector,
  redis,
  post,
  sql,
  cosmos,
  d1,
  d2,
  d3,
  databrick,
  datalake,
  datafactory,
  stream,
  azure,
  machine,
  instances,
  factory,
  fabric,
  registry,
  power,
  softvare
};

const Icon = ({
  id,
  w: width,
  h: height,
  m: margin,
  className,
  onClick,
  htmlFor
}) => {
  const Component = components[id];
  const wP = width ? { width } : null;
  const hP = height ? { height } : null;
  const mP = margin ? { margin } : null;

  const styleProp = wP || hP || mP ? { style: { ...wP, ...hP, ...mP } } : null;

  const onClickProp = onClick ? { onClick } : null;
  const htmlForProp = htmlFor ? { htmlFor } : null;

  return Component ? (
    <Component
      className={className || undefined}
      {...styleProp}
      {...onClickProp}
      {...htmlForProp}
    />
  ) : null;
};

export default memo(Icon);
