import React from 'react';
import * as Styled from './Title.styled';

const Title = ({ size, color, highlighted = false, children, ...rest }) => (
  <Styled.StyledTitle
    size={size}
    color={color}
    highlighted={highlighted}
    {...rest}
  >
    {children}
  </Styled.StyledTitle>
);

export default Title;
