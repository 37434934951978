const humanData = (tr) => [
  {
    id: 1,
    imgSrc: '/Images/Lena.jpg'
  },
  {
    id: 2,
    firstname: tr.human_Firstname,
    name: tr.human_name1,
    text: tr.human_img_text1,
    tel: tr.human_tel1,
    email: tr.human_email1,
    img: 'Images/img2-min.png'
  },
  {
    id: 3,
    imgSrc: '/Images/Narin.jpg'
  },
  {
    id: 4,
    firstname: tr.human_Firstname2,
    name: tr.human_name2,
    text: tr.human_img_text2,
    tel: tr.human_tel2,
    email: tr.human_email2,
    img: 'Images/img4-min.png'
  }
];

export default humanData;
