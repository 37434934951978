import React, { useState } from 'react';
import '../Homepage.scss';
import Carousel from 'react-elastic-carousel';
import MicrosoftTask from './TechnologyStack';
import Icon from './icon';
import Clients from './clients';
import OurTeam from './ourTeam';
import Location from './location';
import { IntlProvider, FormattedMessage } from 'react-intl';
import useLocale from '../hooks/useLocale';
import translation from '../translation';
import Header from './header';
import { useMediaQuery } from 'react-responsive';
import Consultants from './consultants';
import Footer from './footer/footer';

export default function Homepage() {
  const [collapsed, setCollapsed] = useState(false);
  const { getLocale } = useLocale();
  const locale = getLocale();

  const tr = translation[locale];
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const smallLayoutSize = useMediaQuery({ query: `(max-width: 1060px)` });

  const clientData = [
    'c1',
    'c2',
    'c3',
    'c4',
    'c5',
    'c6',
    'c7',
    'c8',
    'c9',
    'c10',
    'c11',
    'c12',
    'c14',
    'c15',
    'c16',
    'c17'
  ];

  return (
    <div className='home'>
      <Header tr={tr} />
      <IntlProvider
        className='button'
        locale={locale}
        messages={translation[locale]}
      >
        <div
          className='start'
          style={{ backgroundImage: `url('/Images/backgroundStart.svg')` }}
        >
          <img src='/Images/aknaHead.svg' alt='aknaHead' />
          <p className='ger'>
            <FormattedMessage
              id='employers'
              defaultMessage='some default one'
              values={{ locale }}
            />
          </p>
        </div>
        <div
          id='iot-solutions'
          className='iot'
          style={{ backgroundImage: `url('/Images/iotBackground.svg')` }}
        >
          <img src='/Images/iotLogo.svg' alt='iot Logo' />
          <h1 className='iotName'>
            IOT <br />
            <FormattedMessage
              id='solutions'
              defaultMessage='some default one'
              values={{ locale }}
            />
          </h1>
          <p className='iotText'>
            <FormattedMessage
              id='industry'
              defaultMessage='some default one'
              values={{ locale }}
            />
            <br />
            <FormattedMessage
              id='point'
              defaultMessage='some default one'
              values={{ locale }}
            />
          </p>
        </div>
        <div className='carousel'>
          <Carousel
            enableSwipe={false}
            enableMouseSwipe={false}
            itemsToScroll={2}
            itemsToShow={isMobile ? 2 : smallLayoutSize ? 3 : 4}
            showArrows={false}
            pagination={!!smallLayoutSize}
          >
            {Array(4)
              .fill()
              .map((el, index) => (
                <div className='item' key={el}>
                  <div className='overlay'>
                    <img
                      className='itemImg'
                      src={`/Images/o${index + 1}.jpg `}
                      alt=''
                    />

                    <p className='hidden'>
                      {tr.country}: {index === 0 && tr.section1_country1}{' '}
                      {index === 1 && tr.section1_country2}{' '}
                      {index === 2 && tr.section1_country3}{' '}
                      {index === 3 && tr.section1_country4}
                    </p>
                  </div>
                  <img
                    alt=''
                    className='arrow'
                    src={
                      !collapsed
                        ? '/Images/downArrow.svg'
                        : '/Images/upArrow.svg'
                    }
                    onClick={() => setCollapsed(!collapsed)}
                  />
                  <div
                    className={`collapse-content ${
                      collapsed ? 'collapsed' : 'expanded'
                    }`}
                    aria-expanded={collapsed}
                  >
                    <p className='text'>
                      {index === 0 && tr.text3}
                      {index === 1 && tr.text2} {index === 2 && tr.text4}{' '}
                      {index === 3 && tr.text1}
                    </p>

                    <p className='country'>
                      {tr.country}: {index === 0 && tr.section1_country1}{' '}
                      {index === 1 && tr.section1_country2}{' '}
                      {index === 2 && tr.section1_country3}{' '}
                      {index === 3 && tr.section1_country4}
                    </p>
                  </div>
                </div>
              ))}
          </Carousel>
        </div>

        <div
          className='cns'
          id='technology'
          style={{
            backgroundImage: `url('/Images/cnsBackground
      .svg')`
          }}
        >
          <Icon id='solution' />
          <img src='/Images/cnsLogo.svg' alt='cnsLogo' />
          <h1 className='cnsName'>
            <FormattedMessage
              id='cloud'
              defaultMessage='some default one'
              values={{ locale }}
            />
            <br />
            <FormattedMessage
              id='native'
              defaultMessage='some default one'
              values={{ locale }}
            />
          </h1>
          <p className='cloud_text'>
            <FormattedMessage
              id='section2_subtitle'
              defaultMessage='some default one'
              values={{ locale }}
            />
          </p>
        </div>
        <MicrosoftTask />
        <div
          id='Ai/Ml'
          className='iot second_iot'
          style={{ backgroundImage: `url('/Images/ml.svg')` }}
        >
          <img src='/Images/brain.svg' alt='iot Logo' />
          <h1 className='iotName'>
            {tr.mlaisolutions} <br />
            {tr.mlaisolutions2 && (
              <FormattedMessage id='mlaisolutions2' values={{ locale }} />
            )}
          </h1>
          <p className='iotText2'>
            <FormattedMessage
              id='mlindustry'
              defaultMessage='some default one'
              values={{ locale }}
            />
          </p>
        </div>
        <div className='carousel'>
          <Carousel
            enableSwipe={false}
            enableMouseSwipe={false}
            itemsToScroll={2}
            itemsToShow={isMobile ? 2 : smallLayoutSize ? 3 : 4}
            showArrows={false}
            pagination={!!smallLayoutSize}
          >
            {Array(4)
              .fill()
              .map((el, index) => (
                <div className='item' key={el}>
                  <div className='overlay'>
                    <img
                      className='itemImg'
                      src={`/Images/z${index + 1}.jpg `}
                      alt=''
                    />
                    <p className='hidden'>
                      {tr.country}: {index === 0 && tr.section3_country1}
                      {index === 1 && tr.section3_country2}
                      {index === 2 && tr.section3_country3}
                      {index === 3 && tr.section3_country4}
                    </p>
                  </div>

                  <img
                    alt=''
                    className='arrow'
                    src={
                      !collapsed
                        ? '/Images/downArrow.svg'
                        : '/Images/upArrow.svg'
                    }
                    onClick={() => setCollapsed(!collapsed)}
                  />
                  <div
                    className={`collapse-content ${
                      collapsed ? 'collapsed' : 'expanded'
                    }`}
                    aria-expanded={collapsed}
                  >
                    <p className='text'>{index === 0 && tr.mltext1}</p>
                    <p className='text'>{index === 1 && tr.mltext2}</p>
                    <p className='text'>{index === 2 && tr.mltext3}</p>
                    <p className='text'>{index === 3 && tr.mltext4}</p>
                    <p className='country'>
                      {tr.country}: {index === 0 && tr.section3_country1}
                      {index === 1 && tr.section3_country2}
                      {index === 2 && tr.section3_country3}
                      {index === 3 && tr.section3_country4}
                    </p>
                  </div>
                </div>
              ))}
          </Carousel>
        </div>
        <Clients data={clientData} tr={tr} />
        <OurTeam tr={tr} />
        <Consultants tr={tr} />
        <Location tr={tr} />
        <Footer tr={tr} />
      </IntlProvider>
    </div>
  );
}
