import { useState } from 'react';

const useLocale = () => {
  const [locale, setLocale] = useState('en');

  const getLocale = () => {
    if (!localStorage.getItem('locale')) {
      return locale;
    }
    return localStorage.getItem('locale');
  };

  const saveLocale = (value) => {
    setLocale(value);
    localStorage.setItem('locale', value);
  };

  return { getLocale, saveLocale };
};

export default useLocale;
