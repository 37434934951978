import { useState, useEffect } from 'react';

const useGeolocation = () => {
  const [error, setError] = useState(null);
  const [countryCode, setCountryCode] = useState(null);

  useEffect(() => {
    const getLocation = async () => {
      try {
        fetch('https://api.ipregistry.co/?key=43uof3gibv0pn64k')
          .then((response) => response.json())
          .then((payload) => setCountryCode(payload?.location?.language?.code));
      } catch (error) {
        setError('Error fetching location data');
      }
    };

    getLocation();
  }, []);

  return { countryCode, error };
};

export default useGeolocation;
