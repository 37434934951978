import React from 'react';
import Icon from '../icon';
import './style.scss';

const TechnologyTable = ({ data }) => (
  <div className='container table_container'>
    <div className='table_content'>
      {data?.map((el) => (
        <div key={el.title}>
          <div className='table_content_title'>
            <span>{el.title}</span>
          </div>
          {el.links.map((link) => (
            <div
              key={link.id}
              className='tech_block'
              style={{ justifyContent: link.title ? 'space-around' : 'center' }}
            >
              <Icon id={link.id} />
              <span>{link.title}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  </div>
);

export default TechnologyTable;
