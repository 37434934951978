import Icon from '../components/icon';

const infoData = [
  {
    title: 'berlin',
    text: 'Germany',
    country: 'ger',
    logo: () => <img src='/Images/ruda2.png' alt='' />,
    infoLinks: [
      {
        target: false,
        link: 'https://goo.gl/maps/FdgAa5pAtzc6xyXMA',
        text: 'Kissinger Str. 67, 14199 Berlin, Deutschland'
      },
      {
        target: false,
        link: 'tel:+493055579263',
        text: 'Tel: +493055579263'
      },
      {
        target: false,
        link: 'mailto:xinfo@ruda-services.com',
        text: 'E-Mail: info@ruda-services.com'
      },
      {
        target: true,
        link: 'http://ruda-services.com',
        text: 'Website:www.ruda-services.com'
      }
    ]
  },
  {
    title: 'yerevan',
    text: 'Armenia',
    country: 'en',
    logo: (isMobile) => (
      <Icon id='akna' w={isMobile ? 45 : 100} h={isMobile ? 45 : 100} />
    ),
    infoLinks: [
      {
        target: false,
        link: 'https://goo.gl/maps/FdgAa5pAtzc6xyXMA',
        text: 'Yekmalyan str. 1, Yerevan, Armenia'
      },
      {
        target: false,
        link: 'tel:+37455203044',
        text: 'Tel:+37455203044'
      },
      {
        target: false,
        link: 'mailto:contact@akna.tech',
        text: 'E-Mail: contact@akna.tech'
      },
      {
        target: true,
        link: 'http://akna.tech/',
        text: 'Website:www.akna.tech'
      }
    ]
  }
];

export default infoData;
