import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Icon from '../icon';
import vacancies from '../../constants/vacancies';
import './style.scss';

const AccordionBox = ({ country, tr }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);

  const handleCheckboxClick = (id) => {
    const index = checkedCheckboxes.indexOf(id);

    if (index !== -1) {
      setCheckedCheckboxes((prevState) => prevState.filter((id) => id !== id));
    } else {
      setCheckedCheckboxes((prevState) => [...prevState, id]);
    }
  };

  return (
    <div className='accordion_container'>
      <div className='row'>
        <div className='col'>
          <div className='tabs'>
            {vacancies(tr)[country]?.map((el) => (
              <div
                className={`tab ${
                  checkedCheckboxes.includes(el.id) ? 'tab_opened' : ''
                }`}
                key={el.id}
              >
                <input
                  type='checkbox'
                  id={el.id}
                  checked={checkedCheckboxes.includes(el.id)}
                />
                <label
                  className='tab_label'
                  htmlFor={el.title}
                  onClick={() => handleCheckboxClick(el.id)}
                >
                  {el.title}
                </label>
                <div className='tab_content'>
                  {el.description && (
                    <div>
                      {el.description}
                    </div>
                  )}
                  <div>
                    <span>{tr.your_contribution}</span>
                    <ul>
                      {el.contribution.map((item) => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <span>{tr.your_qualification}</span>
                    <ul>
                      {el.qualification.map((item) => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  </div>
                  {el.benefits && el.benefits.length ? (
                    <div>
                      <span>{tr.your_benefits}</span>
                      <ul>
                        {el.benefits.map((item) => (
                          <li key={item}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  ) : null}

                  <div className='contact'>
                    {el.applicationDetails ? <span>
                      {el.applicationDetails[0]}{' '}
                      <a href='heghine@akna.tech'>heghine@akna.tech</a>
                      ,{' '}
                      <a href='narine@akna.tech'>narine@akna.tech</a>
                    </span> : <span>
                      {tr.gridbox_cv}{' '}
                      <a href='mailto:yelena@akna.tech'>{tr.gridbox_mail}</a>
                    </span>}
                  </div>
                  <div className='share'>
                    <div>{tr.share_job}</div>
                    <div>
                      <a href='https://www.facebook.com/aknatech'>
                        <Icon
                          id='Facebook'
                          w={isMobile ? 24 : 32}
                          h={isMobile ? 24 : 32}
                        />
                      </a>
                    </div>
                    <div>
                      <a href='https://www.linkedin.com/company/aknatech/'>
                        <Icon
                          id='Linkedin'
                          w={isMobile ? 24 : 32}
                          h={isMobile ? 24 : 32}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionBox;
