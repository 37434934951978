import React from 'react';
import './style.scss';
import InfoCard from '../InfoCard';
import infoData from '../../constants/infoData';
import Title from '../Title';

const Location = ({ tr }) => (
  <div id='our-location' className='container location_container'>
    <Title className='title_lg' size={56} color='#1dc273'>
      {tr.our_location_title}
    </Title>
    <div className='location_content'>
      <div className='location_content_box'>
        <InfoCard data={infoData[0]} />
        <div>
          <iframe
            style={{ borderWidth: '0px' }}
            title='myFrame'
            className='mapp__'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2429.970410959392!2d13.286321215689332!3d52.47967144696971!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a850a3377fa423%3A0x3880eb372d953ebc!2sKissinger%20Str.%2067%2C%2014199%20Berlin%2C%20Germany!5e0!3m2!1sen!2s!4v1639425057966!5m2!1sen!2s'
            allowFullScreen=''
            loading='lazy'
          ></iframe>
        </div>
      </div>
      <div className='location_content_box'>
        <div>
          <iframe
            style={{
              borderWidth: '0px',
              marker: {
                icon: {
                  url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
                }
              }
            }}
            title='myFrame'
            className='mapp__'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3048.1197996214432!2d44.50696791538878!3d40.184147279392775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd02a99cdfc3%3A0x7d090efcb49b1059!2s1%20Yekmalyan%20St%2C%20Yerevan!5e0!3m2!1sen!2s!4v1633338787415!5m2!1sen!2s'
            allowFullScreen=''
            loading='lazy'
          ></iframe>
        </div>
        <InfoCard data={infoData[1]} />
      </div>
    </div>
    <Title className='title_lg' color='#fff' highlighted size={64}>
      {tr.we_support}
    </Title>
  </div>
);

export default Location;
