import React from 'react';
import Title from '../Title';
import TitleText from '../TitleText';
import './style.scss';

const Clients = ({ tr }) => (
  <div id='clients' className='container clients_container'>
    <div className='content'>
      <Title className='title_md' size={55} color='#1dc273'>
        {tr.clients_title}
      </Title>
      <TitleText>{tr.clients_text}</TitleText>
      <div className='clients'>
        <div>
          <img src='/Images/clients3.jpg' alt='' />
        </div>
      </div>
      <Title className='title_md' color='#fff' highlighted size={52}>
        SOLUTIONS | TEAMS | PRODUCTS
      </Title>
    </div>
  </div>
);

export default Clients;
