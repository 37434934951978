import React, { useState } from 'react';
import styled from 'styled-components';
import Carousel from 'react-elastic-carousel';
import { useMediaQuery } from 'react-responsive';
import consultants from '../../constants/consultants';
import Title from '../Title';
import TitleText from '../TitleText';
import './style.scss';

const StyledCarousel = styled(Carousel)`
  .rec-slider {
    left: ${(props) => `${!props.isMobile && 'unset !important'}`};
    gap: 20px;
  }
`;

const Consultants = ({ tr }) => {
  const [collapsed, setCollapsed] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const smallLayoutSize = useMediaQuery({ query: `(max-width: 1060px)` });
  const itemsToShow = isMobile ? 2 : smallLayoutSize ? 3 : 4;

  return (
    <div className='container consultant_container'>
      <Title className='title_lg' size={56} color='#ff4d00'>
        {tr.available_consultants}
      </Title>
      <TitleText>{tr.available_experts}</TitleText>
      <div className='carousel'>
        <StyledCarousel
          isRTL={false}
          enableSwipe={false}
          enableMouseSwipe={false}
          itemsToScroll={2}
          itemsToShow={itemsToShow}
          showArrows={false}
          pagination={!!smallLayoutSize}
          isMobile={isMobile}
        >
          {consultants.map((el) => (
            <div className='consultant_item' key={el.img}>
              <div className='overlay'>
                <img className='consultant_item_img' src={el.img} alt='' />
                <p className='consultant_item_hidden'>
                  {el.position} <br />
                </p>
              </div>

              <img
                alt=''
                className='arrow'
                src={
                  !collapsed ? '/Images/downArrow.svg' : '/Images/upArrow.svg'
                }
                onClick={() => setCollapsed(!collapsed)}
              />
              <div
                className={`collapse-content ${
                  collapsed ? 'collapsed' : 'expanded'
                }`}
                aria-expanded={collapsed}
              >
                <p className='text'>
                  {tr.skills}: {el.skills}
                </p>
                <p className='text'>
                  {tr.projects}: {el.comments}
                </p>
                <p className='text'>
                  {tr.experience}: {el.experience}
                </p>
              </div>
            </div>
          ))}
        </StyledCarousel>
      </div>
    </div>
  );
};

export default Consultants;
