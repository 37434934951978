import React, { useState } from 'react';
import TechnologiesTable from '../TechnologiesTable';
import Title from '../Title';
import technologies from '../../constants/technologies';
import './style.scss';

const TechnologyStack = () => {
  const [collapsed, setCollapsed] = useState(false);

  const handleArrowClick = () => {
    setCollapsed((prevState) => !prevState);
  };

  return (
    <div className='technology_container'>
      <div className='technology_content'>
        <Title className='title_md' size={24} color='#ff4d00'>
          Technology Stack
        </Title>
        <img
          alt=''
          className='arrow'
          src={!collapsed ? '/Images/Vector.jpg' : '/Images/VectorTop.jpg'}
          onClick={handleArrowClick}
        />
        {collapsed && <TechnologiesTable data={technologies} />}
      </div>
    </div>
  );
};

export default TechnologyStack;
