import styled from 'styled-components';

export const StyledTitle = styled('span')`
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.color};
  font-size: ${(props) => `${props.size}px`};
  display: block;
  margin: 30px auto;
  text-shadow: ${(props) =>
    props.highlighted ? '0 0 37px rgba(29,194,115,.37)' : 'unset'};
`;
