const footerLinks = (tr) => [
  {
    id: 1,
    href: '/#iot-solutions',
    text: tr.offerings
  },
  {
    id: 2,
    href: '/#clients',
    text: tr.portfolio
  },
  {
    id: 3,
    href: '/#our-location',
    text: tr.contactUs
  },
  {
    id: 4,
    href: '/#imprint',
    text: tr.imprint
  },
  {
    id: 5,
    href: '/carrers',
    text: tr.careers
  }
];

export default footerLinks;
